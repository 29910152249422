import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
// import login from '../views/login'
// import signup from '../views/signup'
// import dashboard from '../views/dashboard'
// import verificationScreen from '../views/verification'
// import homepage from '../views/homepage'
// import pricing from '../views/pricing'
// import support from '../views/support'
// import product from '../views/product'
// import editAccount from '../views/editAccount'
// import comingSoon from '../views/comingSoon' 


Vue.use(VueRouter)
Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

const routes = [
    {
        path: '/',
        name: 'homepage',
        component: () => import('../views/homepage'),
        meta:{guest: true}
        
      },


      {
        path: '/login',
        name: 'login',
        component:  () => import('../views/login'),
        meta:{guest: true}

        
      },

      {
        path: '/signup',
        name: 'signup',
        component:  () => import('../views/signup'),
        meta:{guest: true}

       
      },

      {
        path: '/dashboard/:userId',
        name: 'dashboard',
        component:  () => import('../views/dashboard'),
        meta:{guest: false}

        
        
      },

      {
        path: '/verification/:verificationCode',
        name: 'verification',
        component:  () => import('../views/verification'),
        meta:{guest: true}

        
      },

      {
        path: '/support',
        name: 'support',
        component:  () => import('../views/support'),
        meta:{guest: true}

        
      },

      {
        path: '/pricing',
        name: 'pricing',
        component:  () => import('../views/pricing'),
        meta:{guest: true}

        
      },

      {
        path: '/product',
        name: 'product',
        component:  () => import('../views/product'),
        meta:{guest: true}

        
      },

      {
        path: '/blog',
        name: 'blog',
        component:  () => import('../views/comingSoon'),
        meta:{guest: true}

        
      },

      {
        path: '/edit/:userId',
        name: 'editAccount',
        component:  () => import('../views/editAccount'),
        meta:{guest: false}

        
      },

      {
        path: '/:garbage',
        name: 'unknown',
        component: () => import('../views/homepage'),
        meta:{guest: true}
        
      },



]




const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior () {
      return { x: 0, y: 0 }
    },
  })

  router.beforeEach((to, from, next) => {
    if(!to.meta.guest){
      var token =  sessionStorage.getItem('token')
      if(token != null){
        return next();
      }
      else{
        return next({path:'/login'});
      }
    }
    return next();
});

  
  export default router