import axios from 'axios';
import Vueaxios from 'vue-axios';
import Vue from 'vue'
import Vuex from 'vuex'
//import router from '../../router'

Vue.use(Vueaxios, axios, Vuex)


export default ({
    state: {
        accessKey: '',
        error : {},
        assignedQuota : '',
        remainingQuota: '',
        usedQuota: '',
        graphData: '',
        maxUsage: 0,
        quota: { '1_month' : { assigned: 0, remaining: 0}},

       

    },

    mutations: {

        setAccessKey: function(state,res){
            state.accessKey = res.token;
        },

        setQuota: function(state, res){
           state.quota = res
        },

        setGraphData: function(state, val){
            //console.log(val)
            var labels = [];
            var data = [];

            for(var i = 0; i < val.length; i++){
                labels.push(val[i]['time'])
                data.push(val[i]['api_usage'])
            }

            state.maxUsage = Math.max(...data);

            
            var dataset = [{
            'data': data,
            backgroundColor: "rgba(224, 248, 255, 0.4)",
            borderColor: "#2196F3",
            pointBackgroundColor: "#2196F3",}]

            state.graphData = 
            { 'labels' : labels, 'datasets': dataset
                
            }


                
            
            
        }

       

    },

    actions: {

        getAccessKey: function({ state,commit,rootState}){
            axios.get(rootState.auth.forexApiUrl + '/token', { headers: rootState.auth.headers })
            .then(function(response) {
                commit('setAccessKey', response.data)
            }).catch(function(error){
                commit('setAccessKey', { token: '..............................................'})
                if(error.response){
                    state.error=error.response.data}
                    else if(error.request){ state.error = { message: "Error occurred while fetching your access key. Please try again."}}
                    else{ state.error = { message:"Oops something went wrong!"}}
            })
        },

        getRemainingQuota: function({rootState, commit, state}){
            axios.get(rootState.auth.forexApiUrl + '/remaining/quota', { headers: rootState.auth.headers })
            .then(function(response) {
                commit('setQuota', response.data)
                
            }).catch(function(error){
                if(error.response){
                    state.error=error.response.data}
                    else if(error.request){ state.error = { message: "Error occurred while fetching your quota. Please try again."}}
                    else{ state.error = { message:"Oops something went wrong!"}}

            })

        },

        resetAccessKey: function({rootState ,commit, state}, validity){
            axios.put(rootState.auth.forexApiUrl + '/reset/token', {token : state.accessKey, no_of_days: validity}, { headers: rootState.auth.headers })
            .then(function(response) {
                commit('setAccessKey', response.data)
            }).catch(function(error){
                if(error.response){
                    state.error=error.response.data}
                    else if(error.request){ state.error = { message: "Error occurred while restting your access key. Please try again."}}
                    else{ state.error = { message:"Oops something went wrong!"}}
            })


        },

        getGraphData: function({rootState,state, commit}, groupBy){
           
            axios.get(rootState.auth.forexApiUrl + '/usage/' + rootState.auth.userInfo.id , {headers : rootState.auth.headers, params: {group_by : groupBy}}, )
            .then((response) => { commit('setGraphData', response.data.data) })
            .catch(function(error){ if(error.response){
                state.error=error.response.data}
                else if(error.request){ state.error = { message: "Error occurred while fetching your graph data. Please try again."}}
                else{ state.error = { message:"Oops something went wrong!"}}})
        }




    }
})