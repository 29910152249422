<template>
  <div :class="$vuetify.breakpoint.mdAndUp? ' ml-12 mr-12 pl-12 pr-12' : 'ma-3'  " style="margin-top: 200px;">
      <v-layout justify-center align-center  >
          <div style="width:10%; border-top: 1px solid black;"> <p>&nbsp;</p></div>
      </v-layout>
      <v-row justify-lg="space-between" justify-sm="center"  >
        <v-col cols="12" sm="6">
          <v-layout justify-start column  >
              <h4 style="font-size:18px; font-weight: 700;" class="mb-5">what is APIjug?</h4>
              <p style=" font-size:18px;line-height:22px; font-weight:300 " :style="$vuetify.breakpoint.mdAndUp?'width:70%':''" >Lorem ipsum dolor sit amet, consectetuamet arcu. Aenean ac ipsum turpis. Cras fringilla dolor diam, in semper eros ullamcorper sed. Maecenas libero sapien, ultricies quis imperdiet at, mattis sit amet arcu.</p>
          </v-layout>
        </v-col>

        <v-col cols="12" sm="6">
          <v-layout justify-start column >
            <h4 style="font-size:18px; font-weight: 700; " class="mb-5">Company Details & address</h4>
            <p style="font-size:18px;line-height:18px; font-weight: 300; "  >79 Ayer Rajah Crescent</p>
            <p style=" font-size:18px;line-height:18px; font-weight: 300;" class="mt-n1">#04-01, NTUitive</p>
            <p style=" font-size:18px;line-height:18px; font-weight: 300; margin-bottom: 55px " class="mt-n1">Singapore 139955</p>

            <h4 style="font-size:18px; font-weight: 700; " class="mb-5">Contact</h4>
            <p style=" font-size:18px;line-height:18px; font-weight: 300; " class=""><v-icon small left>call</v-icon> +65 698888708</p>
            <p style=" font-size:18px;line-height:18px; font-weight: 300; " class="mt-n1"><v-icon small left>mail</v-icon> support@api.com</p>
            <p style=" font-size:18px;line-height:18px; font-weight: 300; margin-bottom: 55px " class="mt-n1"><v-icon small left>alternate_email</v-icon>xyz@email.com</p>
            <!-- <h4 style="font-size:18px; font-weight: 700; " class="mb-5">Social media and website links</h4>
            <p style="width:48%; font-size:18px;line-height:22px; font-weight: 300; "><v-icon small left>whatsapp</v-icon> +91-9876543210</p>
            <p style="width:48%; font-size:18px;line-height:22px; font-weight: 300; "><v-icon small left>whatsapp</v-icon> instagram.com</p>
            <p style="width:48%; font-size:18px;line-height:22px; font-weight: 300; margin-bottom: 55px "><v-icon small left>whatsapp</v-icon>twitter.com</p>-->
   


          </v-layout>
          </v-col>

      </v-row>
  </div>
</template>

<script>
export default {

}
</script>

<style>

.backgroundlogo{
    width: 100%;
  background-image: url('../assets/logo.svg');
  background-position: left 0px bottom 40px;
}


</style>