<template>

    <v-app app @scroll="scrollHandler">
      <v-main>
        <!-- <icmPubSearch v-if="$router.currentRoute.path!='/'"></icmPubSearch> -->
        <cusheader ></cusheader>
        <router-view></router-view>
        <cusfooter v-show="displayFooter"></cusfooter>
        <error-message></error-message>
      </v-main>
    </v-app>
 
  
</template>

<script>
import cusheader from './components/header.vue'
//import errorMessage from './components/errorMessage.vue'
import ErrorMessage from './components/errorMessage.vue';
import cusfooter from './components/footer.vue'

export default {
  name: 'App',
  metaInfo: {
      vmid: 'main',
      title: 'APIjug',
      icon: 'url("@/src/assets/favicon.svg")',
      titleTemplate: '%s | {api}∮jug ',
      content: 'This is an application for subscription of various apis!'
    },

  beforeCreate: function(){
    this.$store.commit('initState')
    if(this.$store.state.auth.isLoggedIn == true){
    this.$store.dispatch('renewToken')}

  },

   methods: {
    scrollHandler(e) {
      console.log('scrolled' + e)
    }
  },

 

  components: {
    cusheader,
    ErrorMessage,
    cusfooter
  },

  computed: {
    displayFooter: function(){
      if(this.$route.name == 'login' || this.$route.name == 'signup'){
        return false
      }
      return true
    }

  },

  data: () => ({
   
   
  }),
};
</script>

<style >
html, 
body {
   
   overflow-x: hidden;
   overflow-y: auto;
   color: #1C1F21
}

h1,h2,h3,h4,h5{
  color: #1d1d1d
}

p{
   color: #1C1F21
}






</style>
