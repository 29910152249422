import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
//import { colors } from "vuetify/lib";
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
    icons:{
        iconfont:'md'
      },
      theme: {
        themes: {
          light: {
            primary: '#2196F3',
            secondary_blue:  '#5CB0FD',
            secondary_white: '#F1F9FF',
            info: '#ffffff',
            error:"#cc0000",
            cancel: '#525252'
            // accent: '#82B1FF',
            // error: '#FF5252',
            // info: '#2196F3',
            // success: '#4CAF50',
            // warning: '#FFC107',
          },
          dark: {
            primary: '#ffffff',
            secondary: '#424242',
            info: '#222',
            accent: '#2196F3',
          }
        }
      }
});
