<template>
<div>
  <v-app-bar app color="white" hide-on-scroll  elevate-on-scroll  :height="$vuetify.breakpoint.width > 1162? 80 : 80"  class="ma-0 pa-0"
    >
    <v-container v-if="$vuetify.breakpoint.width > 1162" class="ma-0 pa-0" style="width: 99%" fluid >
      <v-layout row class="" justify-start >
        <v-img  class="headerImage ml-8" src="@/assets/logo.svg" @click="gotoHomepage" max-height="50" max-width="180" contain></v-img>
        <v-spacer></v-spacer>
        <div class="mt-3 pa-0 mr-2" >
          <v-btn   text @click="gotoHomepage">Home</v-btn>
          <v-btn   text @click="gotoProducts" class="ml-3">Products</v-btn>
          <v-btn   text @click="gotoPricing"  class="ml-3">Pricing</v-btn>
          <v-btn  text @click="gotoBlog"  class="ml-3">Blog</v-btn>
          <v-btn  text @click="gotoSupport"  class="ml-3">Support</v-btn>
          <!-- <v-btn rounded class="mr-1" text>Blog</v-btn> -->
          <v-avatar size="50" class=" ml-5 headerImage" v-if="loggedIn"  @click="gotoDashboard" data-testid="userProfile">
            <v-img src="@/assets/user.png"></v-img>
          </v-avatar>
          <v-btn  width="100" height="35"  class="primary ml-5" small v-else @click="gotoLogin">LOGIN
          </v-btn>
        </div>
      </v-layout>
      <!-- <v-layout row justify-center>
        <icmPubSearch v-if="show_search" transition="slide-x-transition"></icmPubSearch>
      </v-layout> -->
    </v-container>

    <v-container v-else class="ma-0 pa-0" style="width: 98%;" fluid>
      <v-layout row >
        
      <v-app-bar-nav-icon @click="drawer = !drawer" class="mt-2 ml-2"></v-app-bar-nav-icon>

      <v-toolbar-title><v-img  class="headerImage " src="@/assets/logo.svg" max-width="200" @click="gotoHomepage"></v-img></v-toolbar-title>
        
        <v-spacer></v-spacer>


          <v-avatar size="50" class=" ml-2 mr-5 headerImage" v-if="loggedIn" @click="gotoDashboard" data-testid="userProfile">
            <v-img src="@/assets/user.png"></v-img>
          </v-avatar>
          <v-btn  width="100" height="35"  class="primary mt-2" small v-else @click="gotoLogin">LOGIN
          </v-btn>
      </v-layout>

    </v-container>
  </v-app-bar>

  <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      width="310"
      style="position:fixed; top:0; left:0; overflow-y:scroll;"
    >

      <v-layout justify-space-between>
      <v-img  class="headerImage ml-5" src="@/assets/logoapijug.svg" max-width="200px" height="80" @click="gotoHomepage"></v-img>
      <v-icon small class="mr-5" @click="drawer = !drawer">close</v-icon>
      </v-layout>
      <v-divider></v-divider>

      <v-list
        nav
        dense
        class="mt-5 ml-5"
      >
        <v-list-item-group
          active-class="accent--text"
        >
          <v-list-item>

            <v-list-item-title @click="gotoHomepage"><v-icon class="mr-3 mt-2" color="black">home</v-icon><v-btn text>Home</v-btn></v-list-item-title>

          </v-list-item>

          <v-list-item>
            <v-list-item-title @click="gotoProducts"><v-icon class="mr-3 mt-2" color="black">people</v-icon><v-btn  text>Products</v-btn></v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title @click="gotoPricing"><v-icon class="mr-3 mt-2" color="black">collections</v-icon><v-btn   text>Pricing</v-btn></v-list-item-title>
          </v-list-item>

          <v-list-item>

            <v-list-item-title @click="gotoBlog"><v-icon class="mr-3 mt-2" color="black">person_search</v-icon><v-btn  text>Blog</v-btn></v-list-item-title>


          </v-list-item>

           <v-list-item>
            <v-list-item-title @click="gotoSupport"><v-icon class="mr-3" color="black">contact_support</v-icon><v-btn  text>Support</v-btn></v-list-item-title>
          </v-list-item>

           <v-list-item v-if="loggedIn" @click="logout">
            <v-list-item-title><v-icon class="mr-3" color="black" >logout</v-icon><v-btn rounded text>Sign out</v-btn></v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

</div>
</template>

<script>


export default {

 
  
  data: () => ({
    drawer: false,
     
  }),
  computed: {

    loggedIn: function(){
      return this.$store.state.auth.isLoggedIn;
    },
     userInfo: function(){
      return this.$store.state.auth.userInfo;
    },
  },
  methods: {

   
    gotoLogin: function(){
      this.$router.push({ name : 'login'})
    },

    gotoPricing: function(){
      this.$router.push({ name : 'pricing'})
    },

     gotoSupport: function(){
      this.$router.push({ name : 'support'})
    },

     gotoBlog: function(){
      this.$router.push({ name : 'blog'})
    },

     gotoProducts: function(){
      this.$router.push({ name : 'product'})
    },

     gotoHomepage: function(){
      this.$router.push({ name : 'homepage'})
    },

     gotoDashboard: function(){
      this.$router.push({ name : 'dashboard', params: {userId: this.userInfo.id }})
    },

     logout: function(){
      this.$store.dispatch('logout')

    },
  }
};
</script>
<style scoped>
.headerImage:hover{
  cursor: pointer;
}

.v-toolbar__content {
  padding: 0px !important;
}




</style>