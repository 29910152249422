<template>
<v-snackbar v-model="showError" timeout="3000" rounded="pill">
    {{ message }}
    <v-btn style="float:right" @click="showError = false" x-small rounded><v-icon small>close</v-icon></v-btn>
</v-snackbar>
    
</template>

<script>


export default ({

data: () => ({
    showError: false,
    message: ''
}),

computed: {
    authError: function(){
        return this.$store.state.auth.error;
    },

    userDataError: function(){
        return this.$store.state.userdata.error;
    },

},

watch: {
    authError: {
        handler(val){
            if(val){
                this.message = this.authError.message
                this.showError = true
            }
        }
    },

    userDataError: {
        handler(val){
            if(val){
                this.message = this.userDataError.message
                this.showError = true
            }
        }
    },

}

})

</script>

<style scoped>    
    .v-snack__wrapper {
        max-width: none;
    }
</style>




