import axios from 'axios';
import Vueaxios from 'vue-axios';
import Vue from 'vue'
import Vuex from 'vuex'
import router from '../../router'

Vue.use(Vueaxios, axios, Vuex)


export default ({
    state: {
        token: "",
        isLoggedIn: false,
        serverAddress: 'https://forex.apijug.com',
        serverAddressAuth: 'https://auth.apijug.com',
        authApiUrl: 'https://auth.apijug.com' + '/api/v1',
        forexApiUrl : 'https://forex.apijug.com' + '/api/v1',
        // serverAddress: 'https://mldesk.komerco.online:5001',
        // serverAddressAuth: 'https://mldesk.komerco.online:5001',
        // authApiUrl: 'https://mldesk.komerco.online:5001' + '/auth/api/v1',
        // forexApiUrl : 'https://mldesk.komerco.online:5001' + '/currency/api/v1',
        headers: { Authorization: ""},
        userInfo : {},
        error: {},
        renewToken: null,
        verifiedUser: false,
        verificationFailed: false,
    },

    mutations: {


        initState: function(state){
        if(sessionStorage.getItem('token')){
          state.isLoggedIn = true
          state.token = sessionStorage.getItem('token')
          state.headers = { Authorization: 'Bearer ' + state.token}
          state.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        }
        },

        updateToken: function(state, newToken){
            state.token = newToken
            state.headers = { Authorization: 'Bearer ' + state.token}
            sessionStorage.setItem('token', state.token)
        },

        login: function(state, userInfo){
            state.isLoggedIn = true
            state.userInfo = userInfo
            sessionStorage.setItem('userInfo', JSON.stringify(state.userInfo))
        },

        logout: function(state){
            state.isLoggedIn= false
            state.token=''
            state.userInfo={}
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('userInfo')
            
        },

        setUserInfo: function(state, userInfo){
          state.userInfo = userInfo
          //sessionStorage.setItem('userInfo', JSON.stringify(state.userInfo))
        },

        verifiedUser: function(state, val){
          state.verifiedUser = val
        },

        verificationFailed: function(state, val){
          state.verificationFailed = val
        }


        
    },

    actions: {
        login: function ({commit,state, dispatch}, payload) {
           axios.post(state.authApiUrl + '/login', {
              username: payload.username,
              password: payload.password
            })
            .then(function (response) {
              commit('updateToken', response.data.access_token)
              commit('login', response.data.user_information)
              router.push({name : 'dashboard', params: { 'userId' : state.userInfo.id}})
              dispatch('renewToken')
            }).catch(function (error) {
                if(error.response){
                  state.error=error.response.data}
                  else if(error.request){ state.error = { message: "Error occurred while loging in. Please try again."}}
                  else{ state.error = { message:"Oops something went wrong!"}}
            })
              
        },

        renewToken: function({state, commit, dispatch}){
            state.renewToken = window.setInterval(() => {
              axios.get( state.authApiUrl + '/renewtoken', {headers: state.headers})
              .then(function (response) {
                  commit('updateToken', response.data.access_token)})
              .catch(function (error) {
                    if(error.response){
                    state.error=error.response.data}
                    else if(error.request){ state.error = {message:"Authention failed. Please Login again"}}
                    else{ state.error = { message:"Oops something went wrong!"}}
                    dispatch('logout')})
              }, 300000)
            
      
          },
      
          logout: function({state,commit},){
            window.clearInterval(state.renewToken)
            commit('logout')
            router.replace({name : 'login'})

          },

          signUp: function ({state,commit}, payload) {
            axios.post(state.authApiUrl + '/user/registration', {
              person_name: payload.person_name,
              username: payload.username,
              email: payload.email,
              password: payload.password,
            }).then(function () {
              //commit('updateToken', response.data.token)
              commit('setUserInfo', payload)
              router.push({name : 'verification', params: {verificationCode: ''}})
            }).catch(function (error) {
                if(error.response){
                    state.error=error.response.data}
                    else if(error.request){ state.error = {message:"Authention failed. Please Login again"}}
                    else{ state.error = { message:"Oops something went wrong!"}}
              
            });
          },


          verifyUser: function( {state, commit},code){
            if(code != ''){
            axios.get( state.authApiUrl + '/confirm/' + code).then(function(){
              commit('verifiedUser', true)
              router.replace({name: 'login'})
            }).catch(function(error){
              commit('verificationFailed', true)
              if(error.response){
                state.error=error.response.data}
                else if(error.request){ state.error = {message:"Could not verify email. Please try again."}}
                else{ state.error = { message:"Oops something went wrong!"}}

            })
          }

          },

          resendVerificationEmail: function({state}, email){

            axios.get(state.authApiUrl + '/resend', { params : { 'email' : email}, headers: state.headers}).then(function(response){
              console.log(response)
              //commit('verifiedUser', true)
              //router.replace({name: 'login'})
            }).catch(function(error){
              //commit('verificationFailed', true)
              console.log(error)
              if(error.response){
                state.error=error.response.data}
                else if(error.request){ state.error = {message:"Could not resend email. Please try again."}}
                else{ state.error = { message:"Oops something went wrong!"}}

            })

            
          }

    }

})
